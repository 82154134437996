import { useState, useEffect } from "react";
import { useSessionStorage } from "./useSessionStorage";

export default function useAuditRecord() {
  const [value, setValue] = useState();
  const [user, setUser] = useSessionStorage("user", null);
  useEffect(() => {
    fetch(`/api/CreateAuditRecord/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...user, ...value }),
    })
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      });
  }, [value]);

  return [value, setValue];
}
