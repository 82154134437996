import React, { useEffect, useState } from "react";
import AccessContainer from "../common/AccessContainer";
import {
  Spinner,
  Card,
  FormGroup,
  FormText,
  Form,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import useNotification from "../../hooks/sendNotification";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import Papa from "papaparse";
import ImportLog from "./ImportLog";

function CreditControlTaskImport(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [creditControlTasks, setCreditControlTasks] = useLocalStorage(
    "creditControlTasks",
    []
  );
  const [letteringJSON, setLetteringJSON] = useState([]);
  const [msg, sendNotification] = useNotification();
  const [user, setUser] = useSessionStorage("user", null);
  const [fileName, setFileName] = useState("");
  const [uploadCount, setUploadCount] = useState(0);
  useEffect(() => {
    getCreditControlTasks();
  }, []);
  const getCreditControlTasks = () => {
    if (!creditControlTasks.length) {
      fetch(
        "https://prod-16.uksouth.logic.azure.com:443/workflows/01ca7085192d4c5e8a6b9c4c83373021/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=z2jqSKhT5QZinWW9J2dg_2PwFmX36jvh01VV0pnrYKU",
        { headers: { sig: "06X9ZQ4yXJOR0XPW7DroxoIe" } }
      ).then((resp) => {
        resp.json().then((data) => {
          setCreditControlTasks(data);
        });
      });
    }
  };
  const handleLetterChange = (file) => {
    setIsLoading(true);
    Papa.parse(file, {
      skipEmptyLines: "greedy",
      header: false,
      complete: (json) => {
        setLetteringJSON(json.data);
        setIsLoading(false);
        setFileName(file.name);
        sendNotification({
          msg:
            "Processing complete, " +
            (json.data.length - 1) +
            " cases found. Click the upload button to send to Helix",
          variant: "info",
        });
      },
      error: (ex, file) => {
        sendNotification({ msg: "ERROR: " + ex, variant: "danger" });
      },
    });
  };
  const sendLetterJson = () => {
    if (!selectedOption) {
      sendNotification({
        msg: "Please select a credit control task.",
        variant: "danger",
      });
      return;
    }
    setIsLoading(true);
    fetch(`/api/Finance/CreateFinanceCreditControlImport`, {
      method: "POST",
      headers: {
        user: JSON.stringify(user),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        task: selectedOption,
        json: letteringJSON,
        fileName: fileName,
      }),
    })
      .then((resp) => {
        setIsLoading(false);
        if (!resp.ok) {
          sendNotification({ msg: "ERROR during upload", variant: "danger" });
          return;
        }
        sendNotification({
          msg: "Upload to Helix Complete",
          variant: "success",
        });
        setUploadCount(uploadCount + 1);
      })
      .catch((ex) => {
        sendNotification({ msg: "ERROR: " + ex, variant: "danger" });
        setIsLoading(false);
      });
  };

  return (
    <AccessContainer allowedRoles={["HelixFinance", "NexusAdmin"]}>
      {isLoading && <Spinner animation="border" />}
      <Card style={{ width: "50rem" }}>
        <Card.Header>
          <h4>Helix Credit Control Task Bulk Upload</h4>
        </Card.Header>
        <Card.Body>
          <FormGroup>
            <FormText color="muted">
              Upload a CSV file with two columns "CaseID, Description", and
              Select a credit control task. Helix cases will be automatically
              updated with the selected control task.
            </FormText>
            <Row>
              <Col md="6">
                <Form.Control
                  style={{ padding: "10px 0" }}
                  type="file"
                  name="externalLetterFile"
                  id="externalLetterFile"
                  accept=".csv"
                  onChange={(e) => {
                    handleLetterChange(e.target.files[0]);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Row>
                  {creditControlTasks && (
                    <Col>
                      <select
                        onChange={(e) => {
                          setSelectedOption({
                            id: e.target.value,
                            name: e.target[e.target.selectedIndex].getAttribute(
                              "name"
                            ),
                          });
                        }}
                      >
                        <option>Select an option</option>
                        {creditControlTasks.map((item) => {
                          return (
                            <option
                              key={item.ProcedureTestName}
                              name={item.ProcedureTestName}
                              value={item.ProcedureTestId}
                            >
                              {item.ProcedureTestName}
                            </option>
                          );
                        })}
                      </select>
                    </Col>
                  )}
                  <Col className="mt-3">
                    <Button color="primary" onClick={() => sendLetterJson()}>
                      Upload
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormGroup>
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Body>
          <ImportLog
            uploadCount={uploadCount}
            category="HELIX_CREDIT_CONTROL_IMPORT"
          />
          <ImportLog
            uploadCount={uploadCount}
            title="Error Import log"
            category="HELIX_CREDITCONTROL_IMPORT_ERROR"
          />
        </Card.Body>
      </Card>
    </AccessContainer>
  );
}
export default CreditControlTaskImport;
