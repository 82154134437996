import React, { useEffect, useState } from "react";

import { Row, Col } from "react-bootstrap";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";

import AccessContainer from "../common/AccessContainer";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import DepartmentDropdown from "./DepartmentDropdown";
import HolidayOpenCloseTimes from "./HolidayOpenCloseTimes";
import ClinicDropdown from "./ClinicDropdown";
import HolidayDropdown from "./HolidayDropDown";
import { checkAccess } from "../../utils";

function CallFlowLayoutHolidays(props) {
  const [clinicName, setClinicName] = useState([]);
  const [department, setDepartment] = useState([]);
  const [ccUpdateFields, setCcUpdateFields] = useState(null);
  const [clinics, setClinics] = useState([]);
  const [clinicData, setClinicData] = useState(null);
  const [user] = useSessionStorage("user", null);
  const [open, setOpen] = useState(false);
  const [departmentPrefix, setDepartmentPrefix] = useState(null);
  const [holidaySuffix, setHolidaySuffix] = useState(null);
  const holidaySelect = "holiday";

  useEffect(() => {
    fetch(`/api/CallFlow/GetClinicsMitel/`)
      .then((response) => response.json())
      .then((items) => {
        setClinics(items);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleDepartmentChange = (value) => {
    if (value === "clinic") {
      setDepartmentPrefix("Clinical");
    } else {
      setDepartmentPrefix("CC");
    }
  };

  const handleHolidaySelect = (value) => {
    console.log(value);
    setHolidaySuffix(value);
  };

  const handleClinicSelect = (event) => {
    const {
      target: { value },
    } = event;
    setClinicName(typeof value === "string" ? value.split(",") : value);
    console.log(value);
    fetch(`/api/CallFlow/GetSingleRowForClinicsMitel/`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(value),
    })
      .then((response) => {
        setCcUpdateFields(null);
        return response.json();
      })
      .then((items) => {
        console.log(items);
        setClinicData(items);
      })
      .catch((err) => {
        setCcUpdateFields(null);
        console.log(err);
      });
  };

  const handleSaveClinic = (event) => {
    if (!department || !clinicName.length || !holidaySuffix)
      return alert(
        "Please select a department, atleast 1 clinic and a Holiday"
      );

    const reqBody =
      department === "clinic"
        ? { data: ccUpdateFields, sites: clinicName }
        : { data: ccUpdateFields, sites: clinicName };
    const url =
      department === "clinic"
        ? "/api/CallFlow/UpdateContactCentreMitel/"
        : "/api/CallFlow/UpdateContactCentreMitel/";
    if (window.confirm("Are you sure you want to save?")) {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          user: user.userDetails,
        },
        method: "POST",
        body: JSON.stringify(reqBody),
      })
        .then((response) => response.json())
        .then((items) => {
          setClinicData((clinicData) => ({
            ...clinicData,
            ...{ ModifiedBy: user.userDetails, ModifiedTime: new Date() },
          }));
          console.log(items);
        })
        .catch((err) => {
          console.log(err);
        });
      console.log("saved Data:", clinicData);
      setOpen(true);
    }
  };

  return (
    <AccessContainer
      allowedRoles={[
        "NexusAdmin",
        "MitelAdmin",
        "CallFlowAreaStaff",
        "CallFlowDistrictStaff",
        "CallFlowClinicStaff",
      ]}
    >
      {!checkAccess(
        [
          "NexusAdmin",
          "MitelAdmin",
          "CallFlowAreaStaff",
          "CallFlowDistrictStaff",
        ],
        user
      ) && (
        <MuiAlert severity="warning" style={{ marginBottom: 10 }}>
          Specific call flow paths are disabled based on your job role
        </MuiAlert>
      )}
      <Row style={{ minHeight: "75vh" }}>
        <Col md={{ span: 8 }}>
          <Row style={{ rowGap: 15 }}>
            <Col md={{ span: 4 }}>
              <DepartmentDropdown
                handleDepartmentChange={handleDepartmentChange}
                setClinicName={setClinicName}
                setDepartment={setDepartment}
                user={user}
                HolidaySelect={holidaySelect}
              />
            </Col>
            <Col md={{ span: 4 }}>
              <ClinicDropdown
                handleClinicSelect={handleClinicSelect}
                Clinics={clinics}
                ClinicName={clinicName}
                Department={department}
                HolidaySelect={holidaySelect}
              />
            </Col>
            <Col md={{ span: 4 }}>
              {department.length > 0 && (
                <HolidayDropdown
                  Clinics={clinics}
                  ClinicName={clinicName}
                  Department={department}
                  DepartmentPrefix={departmentPrefix}
                  handleHolidaySelect={handleHolidaySelect}
                />
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Col md={{ span: 12 }}>
              <HolidayOpenCloseTimes
                ClinicData={clinicData}
                SetClinicData={setCcUpdateFields}
                DepartmentPrefix={departmentPrefix}
                HolidaySuffix={holidaySuffix}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: "15px", rowGap: "15px", marginBottom: 15 }}>
        <Col md={{ span: 6 }}>
          {clinicData && clinicData.ModifiedBy && (
            <MuiAlert severity="info">
              Last updated by {clinicData.ModifiedBy} @{" "}
              {new Date(clinicData.ModifiedTime).toLocaleString("en-GB")}
            </MuiAlert>
          )}
        </Col>
        <Col md={{ span: 6 }}>
          {clinicData && (
            <Button
              onClick={handleSaveClinic}
              style={{ marginLeft: 15 }}
              variant="outlined"
            >
              Save
            </Button>
          )}
        </Col>
      </Row>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Succesfully saved this record
        </Alert>
      </Snackbar>
    </AccessContainer>
  );
}
export default CallFlowLayoutHolidays;
