import React, { useEffect, useState } from "react";
import {
  InputGroup,
  Button,
  FormControl,
  Row,
  Col,
  Spinner,
  Badge,
  Modal,
} from "react-bootstrap";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import MUIDataTable from "mui-datatables";
import JournalItemTable from "../helix-audit/JournalItemTable";
import AccessContainer from "../common/AccessContainer";

const parentColumns = [
  {
    name: "RecordId",
    options: {
      display: false,
    },
  },
  {
    name: "AuditDate",
    label: "Audit Date",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => (
        <>{new Date(value).toLocaleString("en-GB")}</>
      ),
    },
  },
  { name: "UpdatedBy", label: "Created By" },
  {
    name: "RecordCount",
    label: "Modification Count",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (value === 0) return <p className="text-center">0</p>;
        return (
          <h4 className="text-center">
            <Badge className="bg-primary">{value}</Badge>
          </h4>
        );
      },
    },
  },
  {
    name: "ItemTypeId",
    label: "Type",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (!value) return;
        return /\(([^)]+)\)/.exec(value)[1];
      },
    },
  },
  {
    name: "Description",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (!value) return;
        return (
          <div
            style={{ whiteSpace: "pre-line" }}
            dangerouslySetInnerHTML={{ __html: value }}
          />
        );
      },
    },
  },
  {
    name: "JournalDateTime",
    label: "Journal Date",
  },
  { name: "CaseResponsibleVet", label: "Responsible" },
  { name: "ResponsibleVet", label: "Vet" },
];

function AuditJournal(props) {
  const [data, setData] = useState([]);
  const [caseId, setCaseId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [auditRecordId, setAuditRecordId] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [updatesOnly, setUpdatesOnly] = useState(true);

  const tableOptions = {
    selectableRows: "none",
    fixedHeader: false,
    responsive: "standard",
    expandableRows: false,
    expandableRowsOnClick: false,
    expandableRowsHeader: false,
    download: false,
    print: false,
    onRowClick: (rowData, rowMeta) => {
      setAuditRecordId(rowData[0]);
      setModalShow(true);
    },
    setTableProps: () => ({ className: "case-Journal" }),
  };

  useEffect(() => {
    if (props.caseId) {
      setCaseId(props.caseId);
      loadData(props.caseId);
    } else {
      loadData(caseId);
    }
  }, [props.caseId, updatesOnly]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      loadData(caseId);
    }
  };

  const loadData = (caseId) => {
    if (caseId) {
      setIsLoading(true);
      fetch(`/api/HelixAudit/CaseJournalItems/${caseId}/${updatesOnly}`)
        .then((response) => response.json())
        .then((items) => {
          setData(items);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };
  return (
    <AccessContainer allowedRoles={["NexusAdmin", "HelixAudit"]}>
      <Row>
        <Col md={4}>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Enter a Case Id"
              type="number"
              aria-label="Enter a Case Id"
              aria-describedby="button-Search"
              value={caseId}
              onChange={(e) => setCaseId(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <Button
              variant="primary"
              id="button-Search"
              onClick={() => {
                loadData(caseId);
              }}
            >
              Search
            </Button>
          </InputGroup>
        </Col>
        <Col md={2}> {isLoading && <Spinner animation="border" />}</Col>
        <Col md={6}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={updatesOnly}
                  onChange={() => {
                    setUpdatesOnly(!updatesOnly);
                  }}
                />
              }
              label="Only show records with modifications"
            ></FormControlLabel>
          </FormGroup>
        </Col>
      </Row>
      {data && (
        <MUIDataTable
          data={data}
          columns={parentColumns}
          title={`Case Journal ${caseId ? "- #" + caseId : ""}`}
          options={tableOptions}
        />
      )}
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Item Audit Trail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <JournalItemTable recordId={auditRecordId} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </AccessContainer>
  );
}
export default AuditJournal;
