import React from "react";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function clinicDropdown(props) {

  return (
    <>
      {props.Department === "contact-centre" && props.HolidaySelect !== 'holiday' && (
        <FormControl style={{ width: "100%" }}>
          <InputLabel id="clinics-checkbox-label">Clinics</InputLabel>
          <Select
            labelId="clinics-checkbox-label"
            id="clinics-checkbox"
            multiple
            value={props.ClinicName}
            onChange={props.handleClinicSelect}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {props.Clinics.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={props.ClinicName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {props.HolidaySelect === 'holiday' && (
        <FormControl style={{ width: "100%" }}>
          <InputLabel id="clinics-checkbox-label">Clinics</InputLabel>
          <Select
            labelId="clinics-checkbox-label"
            id="clinics-checkbox"
            multiple
            value={props.ClinicName}
            onChange={props.handleClinicSelect}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {props.Clinics.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={props.ClinicName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {props.Department === "clinic" && props.HolidaySelect !== 'holiday' && (
        <FormControl style={{ width: "100%" }} variant="filled">
          <InputLabel id="clinic-single-select-label">Clinic:</InputLabel>
          <Select
            labelId="clinic-single-select-label"
            id="clinic-single-select"
            label="Clinic"
            onChange={props.handleClinicSelect}
          >
            {props.Clinics.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
}

export default clinicDropdown;
