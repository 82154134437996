import React, { useEffect, useState } from "react";
import AccessContainer from "../common/AccessContainer";
import { Spinner, Modal, NavLink } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
const creditControlColumns = [
  {
    name: "CaseId",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => (
        <NavLink
          target="_blank"
          href={`https://helix.vets-now.com/CaseDetail.aspx?id=${value}`}
        >
          {value}
        </NavLink>
      ),
    },
  },
  "Description",
  "Task",
];
const paymentColumns = [
  "ClientId",
  {
    name: "CaseId",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => (
        <NavLink
          target="_blank"
          href={`https://helix.vets-now.com/CaseDetail.aspx?id=${value}`}
        >
          {value}
        </NavLink>
      ),
    },
  },
  "InvoiceNumber",
  "TenderType",
  "CustomerType",
  "Value",
  {
    name: "Notes",
  },
  {
    name: "Validation",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => (
        <>
          {value && (
            <div
              data-content={value}
              style={{
                color: value.indexOf("Successfully") < 0 ? "red" : "green",
              }}
            >
              {value}
            </div>
          )}
        </>
      ),
    },
  },
];

const parentColumns = [
  {
    name: "_ts",
    label: "Time",
    options: {
      customBodyRender: (item, meta) =>
        `${new Date(item * 1000).toLocaleDateString()} ${new Date(
          item * 1000
        ).toLocaleTimeString()}`,
    },
  },
  { name: "userDetails", label: "User" },
  {
    name: "description",
    label: "Import data",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => (
        <>
          <div data-content={value}>
            {JSON.stringify(value).substring(0, 16) + "..."}
          </div>
        </>
      ),
    },
  },
  { name: "category", label: "Category" },
  { name: "fileName", label: "File" },
];

function ImportLog(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowContent, setRowContent] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const parentOptions = {
    selectableRows: "true",
    selectableRowsHideCheckboxes: true,
    expandableRows: false,
    expandableRowsOnClick: false,
    rowsPerPage: 10,
    responsive: "scroll",
    onRowClick: (rowData, rowMeta) => {
      if (
        rowData[2].props.children.props["data-content"].name ===
        "StatusCodeError"
      ) {
        return prompt(
          "Copy error to clipboard: Ctrl+C, Enter",
          JSON.stringify(rowData[2].props.children.props["data-content"])
        );
      }
      setRowContent(rowData[2].props.children.props["data-content"]);
      setModalTitle(`Import log for file: "${rowData[4]}"`);
      setModalShow(true);
    },
  };
  useEffect(() => {
    getAuditLog();
  }, [props.uploadCount]);

  const getAuditLog = () => {
    if (!props.category) return;
    fetch(`/api/NexusAudit/GetNexusAuditTrail/${props.category}`)
      .then((response) => response.json())
      .then((items) => {
        setData(items);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <AccessContainer allowedRoles={["HelixFinance", "NexusAdmin"]}>
      {isLoading && <Spinner animation="border" />}
      {data && (
        <>
          <MUIDataTable
            data={data}
            columns={parentColumns}
            title={props.title || "Import log"}
            options={parentOptions}
          />
          <Modal
            size="xxl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={modalShow}
            onHide={() => setModalShow(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {modalTitle}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <MUIDataTable
                title="Import data"
                columns={
                  props.category === "HELIX_PAYMENT_IMPORT"
                    ? paymentColumns
                    : creditControlColumns
                }
                data={rowContent}
                options={{ rowsPerPage: 10, selectableRows: "none" }}
              />
            </Modal.Body>
          </Modal>
        </>
      )}
    </AccessContainer>
  );
}
export default ImportLog;
