import React from "react";
import { checkAccess } from "../../utils";

import logo from "../../assets/react-logo.png";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import Search from "@material-ui/icons/Search";
import ClientIcon from "@material-ui/icons/Person";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import PaymentsIcon from "@material-ui/icons/ImportExport";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import CallIcon from "@material-ui/icons/CallMerge";
import LocumIcon from "@material-ui/icons/Storage";
import styled from "styled-components";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import Animal from "react-animals";
import ClosedCaptionIcon from "@mui/icons-material/ClosedCaption";

const MenuHeader = styled.div`
  padding-left: 15px;
  color: #fffbfb82;
`;

function Sidebar(props) {
  return (
    <Drawer
      anchor="left"
      open={props.open}
      onClose={() => {
        props.toggleSideBar();
      }}
    >
      <Box
        sx={{ width: 250, marginTop: 80 }}
        role="presentation"
        onClick={() => props.toggleSideBar()}
        onKeyDown={() => props.toggleSideBar()}
      >
        <img
          src={logo}
          alt="Vets Now Logo"
          style={{ width: "150px", marginLeft: "35px" }}
        />
        <Divider />
        <List>
          {checkAccess(["HelixAudit", "NexusAdmin"], props.user) && (
            <>
              <MenuHeader>Helix Audit</MenuHeader>
              <ListItem
                button
                key="CaseStatus"
                component="a"
                href="/CaseStatusAudit/"
              >
                <ListItemIcon>
                  <CompareArrowsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Case Status" />
                <Animal size="30px" />
              </ListItem>
              <ListItem
                button
                key="CaseJournalAudit"
                component="a"
                href="/CaseJournalAudit/"
              >
                <ListItemIcon>
                  <MenuBookIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Case Journal" />
                <Animal size="30px" />
              </ListItem>
              <ListItem button key="ClientAudit" component="a" href="/Client/">
                <ListItemIcon>
                  <ClientIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Client" />
                <Animal size="30px" />
              </ListItem>
              <ListItem
                button
                key="CasePractice"
                component="a"
                href="/CasePractice/"
              >
                <ListItemIcon>
                  <HomeWorkIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Client Practice" />
                <Animal size="30px" />
              </ListItem>
              <ListItem
                button
                key="EnhancedSearch"
                component="a"
                href="/EnhancedSearch/"
              >
                <ListItemIcon>
                  <Search fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Enhanced Search" />
                <Animal size="30px" />
              </ListItem>
              <Divider />
            </>
          )}
          <>
            <MenuHeader>Call Flow</MenuHeader>
            {checkAccess(
              [
                "NexusAdmin",
                "MitelAdmin",
                "CallFlowDistrictStaff",
                "CallFlowAreaStaff",
                "CallFlowClinicStaff",
              ],
              props.user
            ) && (
              <ListItem button key="CallFlow" component="a" href="/CallFlow/">
                <ListItemIcon>
                  <CallIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Call Flow " />
                <Animal size="30px" />
              </ListItem>
            )}
            {checkAccess(["NexusAdmin", "MitelAdmin"], props.user) && (
              <ListItem
                button
                key="CallFlowHolidays"
                component="a"
                href="/CallFlowHolidays/"
              >
                <ListItemIcon>
                  <CallIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Call Flow Holidays" />
                <Animal size="30px" />
              </ListItem>
              
            )}
            {checkAccess(
            [
              "NexusAdmin",
              "MitelAdmin",
            ],
            props.user
            ) && (
              <ListItem button key="ViewNumbers" component="a" href="/ViewNumbers/">
                <ListItemIcon>
                  <CallIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="View all numbers" />
                <Animal size="30px" />
              </ListItem>
              
            )}
            <Divider />
          </>
          {checkAccess(["HelixFinance"], props.user) && (
            <>
              <MenuHeader>Helix Finance</MenuHeader>
              <ListItem
                button
                key="PaymentImport"
                component="a"
                href="/PaymentImport/"
              >
                <ListItemIcon>
                  <PaymentsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Payment Import" />
                <Animal size="30px" />
              </ListItem>
              <ListItem
                button
                key="CreditControlTaskImport"
                component="a"
                href="/CreditControlTaskImport/"
              >
                <ListItemIcon>
                  <CreditCardIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Credit Control Import " />
                <Animal size="30px" />
              </ListItem>
              <Divider />
            </>
          )}
          {checkAccess(["NexusAdmin", "LocumReport"], props.user) && (
            <>
              <MenuHeader>Locum Bank</MenuHeader>
              <ListItem
                button
                key="FreshCRM"
                component="a"
                href="/LocumReport/"
              >
                <ListItemIcon>
                  <LocumIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Locum Bank" />
                <Animal size="30px" />
              </ListItem>
              <ListItem
                button
                key="FreshCRMAudit"
                component="a"
                href="/LocumReportAccessAudit/"
              >
                <ListItemIcon>
                  <MenuBookIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Audit Trail" />
                <Animal size="30px" />
              </ListItem>
              <Divider />
            </>
          )}

          {checkAccess(["NexusAdmin", "HelixFinance"], props.user) && (
            <>
              <MenuHeader>Row Level Security</MenuHeader>

              <ListItem
                button
                key="Report Security"
                component="a"
                href="/ReportSecurity/"
              >
                <ListItemIcon>
                  <ClosedCaptionIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Report Security" />
                <Animal size="30px" />
              </ListItem>

              <Divider />
            </>
          )}
        </List>
      </Box>
    </Drawer>
  );
}

export default Sidebar;
