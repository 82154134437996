import React, { useEffect, useState } from "react";
import AccessContainer from "../../common/AccessContainer";
import { Button, Row, Col, Spinner } from "react-bootstrap";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
import useNotification from "../../../hooks/sendNotification";
import MUIDataTable from "mui-datatables";

function PaymentImport(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [validCount, setValidCount] = useState(0);
  const [invalidCount, setInvalidCount] = useState(0);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [importData, setImportData] = useState([]);
  const [user, setUser] = useSessionStorage("user", null);
  const [msg, sendNotification] = useNotification();

  useEffect(() => {
    if (props.data) {
      setImportData(props.data.data.slice(1));
    }
  }, [props.data]);

  const BeginValidation = () => {
    setIsLoading(true);
    fetch(`/api/Finance/GetFinanceValidatePayments`, {
      method: "POST",
      headers: {
        user: JSON.stringify(user),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ json: importData }),
    })
      .then((resp) => {
        if (!resp.ok) {
          sendNotification({
            msg: "ERROR during upload",
            variant: "danger",
          });
          return;
        }

        resp
          .json()
          .then((json) => {
            setIsLoading(false);
            setImportData(
              json.sort((a, b) =>
                a[7].indexOf("Successfully") > 0
                  ? 1
                  : b[7].indexOf("Successfully") > 0
                  ? -1
                  : 0
              )
            );
            setIsValidated(true);
            setValidCount(
              json.filter((i) => {
                return i[7].indexOf("Successfully") > 0;
              }).length
            );
            setInvalidCount(
              json.filter((i) => {
                return i[7].indexOf("Successfully") < 0;
              }).length
            );
            sendNotification({
              msg: "Validation complete",
              variant: "success",
            });
          })
          .catch((ex) => {
            sendNotification({
              msg: "ERROR:" + ex,
              variant: "danger",
            });
            setIsLoading(false);
          });
      })
      .catch((ex) => {
        sendNotification({
          msg: "ERROR:" + ex,
          variant: "danger",
        });
        setIsLoading(false);
      });
  };
  const BeginUpload = () => {
    setIsLoading(true);
    fetch(`/api/Finance/CreateFinancePaymentsUpload`, {
      method: "POST",
      headers: {
        user: JSON.stringify(user),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        json: importData,
        fileName: props.file.name,
      }),
    })
      .then((resp) => {
        setIsLoading(false);
        if (!resp.ok) {
          sendNotification({ msg: "ERRORd uring upload", variant: "danger" });
          return;
        }

        resp.json().then((json) => {
          const ValidCount = json.filter((i) => {
            return i[7].indexOf("Successfully") > 0;
          }).length;
          const InvalidCount = json.filter((i) => {
            return i[7].indexOf("Successfully") < 0;
          }).length;
          setImportData(
            json.sort((a, b) =>
              a[7].indexOf("Successfully") > 0
                ? 1
                : b[7].indexOf("Successfully") > 0
                ? -1
                : 0
            )
          );
          setValidCount(ValidCount);
          setInvalidCount(InvalidCount);
          setIsUploaded(true);
          props.setUpload(props.uploadCount + 1);
          sendNotification({
            msg: `Upload finished, Of ${
              ValidCount + InvalidCount
            } records - ${InvalidCount} failed`,
            variant: InvalidCount > 0 ? "warning" : "success",
          });
        });
      })
      .catch((ex) => {
        sendNotification({ msg: "ERROR: " + ex, variant: "danger" });
        setIsLoading(false);
      });
  };
  const columns = [
    "ClientId",
    "CaseId",
    "InvoiceNumber",
    "TenderType",
    "CustomerType",
    "Value",
    {
      name: "Notes",
    },
    {
      name: "Validation",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            {value && (
              <div
                data-content={value}
                style={{
                  color: value.indexOf("Successfully") < 0 ? "red" : "green",
                }}
              >
                {value}
              </div>
            )}
          </>
        ),
      },
    },
  ];
  const options = {
    expandableRows: false,
    rowsPerPage: 10,
    selectableRows: "none",
    responsive: "scroll",
  };

  return (
    <>
      {isLoading && <Spinner animation="border" />}

      <Row className="text-center white-content">
        <MUIDataTable
          title="Import data"
          columns={columns}
          data={importData}
          options={options}
        />
      </Row>
      <Row className="mt-3">
        <Col>
          <Button
            variant="secondary"
            disabled={isUploaded}
            onClick={() => {
              BeginValidation();
            }}
          >
            Validatate Payments
          </Button>
          <Button
            variant="primary"
            disabled={!isValidated || isUploaded}
            onClick={() => {
              BeginUpload();
            }}
          >
            Upload to Helix
          </Button>
        </Col>
        {validCount + invalidCount > 0 && (
          <Col>
            <Stack direction="row" spacing={1}>
              <Chip
                color="primary"
                label={`Total: ${validCount + invalidCount}`}
              />
              <Chip color="info" label={`Valid:${validCount}`} />
              <Chip color="warning" label={`Invalid: ${invalidCount}`} />
            </Stack>
          </Col>
        )}
      </Row>
    </>
  );
}
export default PaymentImport;
