import React from "react";

import TextField from "@mui/material/TextField";
import { Row, Col } from "react-bootstrap";

const convertTimeString = (date) => {
  return (
    padTo2Digits(new Date(date).getUTCHours()) +
    ":" +
    padTo2Digits(new Date(date).getUTCMinutes())
  );
};

const xs = { span: 6 };

const padTo2Digits = (num) => num.toString().padStart(2, "0");

const handleTimeChange = (e, field, props) => {
  let updatedTime = props.ClinicData[field]
    ? new Date(props.ClinicData[field])
    : new Date(null);
  updatedTime.setUTCHours(
    e.target.value.split(":")[0],
    e.target.value.split(":")[1]
  );
  props.SetClinicData((ClinicData) => ({
    ...ClinicData,
    ...{ [field]: updatedTime.toISOString() },
  }));
};

function HolidayOpenCloseTimes(props) {
  return (
    props.ClinicData && (
      <div key={props.ClinicData.DNIS}>
        <Row>
          <Col style={{ fontWeight: "600" }} md={2} xs={xs}>
            Overnight Opening Time
          </Col>
          <Col style={{ fontWeight: "600" }} md={2} xs={xs}>
            Overnight Closing Time
          </Col>
          <Col style={{ fontWeight: "600" }} md={2} xs={xs}>
            Opening Time
          </Col>
          <Col style={{ fontWeight: "600" }} md={2} xs={xs}>
            Closing Time
          </Col>

          <Col style={{ fontWeight: "600" }} md={4} xs={xs}>
            Date
          </Col>
        </Row>
        <Row>
          <Col md={2} xs={xs}>
            <TextField
              id={
                props.DepartmentPrefix +
                "HolidayOvernightStartTime" +
                props.HolidaySuffix
              }
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(
                  e,
                  props.DepartmentPrefix +
                    "HolidayOvernightStartTime" +
                    props.HolidaySuffix,
                  props
                )
              }
              value={
                props.ClinicData[
                  props.DepartmentPrefix +
                    "HolidayOvernightStartTime" +
                    props.HolidaySuffix
                ] &&
                convertTimeString(
                  props.ClinicData[
                    props.DepartmentPrefix +
                      "HolidayOvernightStartTime" +
                      props.HolidaySuffix
                  ]
                )
              }
            />
          </Col>
          <Col md={2} xs={xs}>
            <TextField
              id={
                props.DepartmentPrefix +
                "HolidayOvernightEndTime" +
                props.HolidaySuffix
              }
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(
                  e,
                  props.DepartmentPrefix +
                    "HolidayOvernightEndTime" +
                    props.HolidaySuffix,
                  props
                )
              }
              value={
                props.ClinicData[
                  props.DepartmentPrefix +
                    "HolidayOvernightEndTime" +
                    props.HolidaySuffix
                ] &&
                convertTimeString(
                  props.ClinicData[
                    props.DepartmentPrefix +
                      "HolidayOvernightEndTime" +
                      props.HolidaySuffix
                  ]
                )
              }
            />
          </Col>
          <Col md={2} xs={xs}>
            <TextField
              id={
                props.DepartmentPrefix +
                "HolidayStartTime" +
                props.HolidaySuffix
              }
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(
                  e,
                  props.DepartmentPrefix +
                    "HolidayStartTime" +
                    props.HolidaySuffix,
                  props
                )
              }
              value={
                props.ClinicData[
                  props.DepartmentPrefix +
                    "HolidayStartTime" +
                    props.HolidaySuffix
                ] &&
                convertTimeString(
                  props.ClinicData[
                    props.DepartmentPrefix +
                      "HolidayStartTime" +
                      props.HolidaySuffix
                  ]
                )
              }
            />
          </Col>
          <Col md={2} xs={xs}>
            <TextField
              id={
                props.DepartmentPrefix + "HolidayEndTime" + props.HolidaySuffix
              }
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(
                  e,
                  props.DepartmentPrefix +
                    "HolidayEndTime" +
                    props.HolidaySuffix,
                  props
                )
              }
              value={
                props.ClinicData[
                  props.DepartmentPrefix +
                    "HolidayEndTime" +
                    props.HolidaySuffix
                ] &&
                convertTimeString(
                  props.ClinicData[
                    props.DepartmentPrefix +
                      "HolidayEndTime" +
                      props.HolidaySuffix
                  ]
                )
              }
            />
          </Col>

          <Col md={4} xs={xs}>
            <TextField
              disabled
              id={
                props.DepartmentPrefix +
                "HolidayStartDate" +
                props.HolidaySuffix
              }
              variant="outlined"
              type="date"
              value={
                props.ClinicData[
                  props.DepartmentPrefix +
                    "HolidayStartDate" +
                    props.HolidaySuffix
                ] &&
                new Date(
                  props.ClinicData[
                    props.DepartmentPrefix +
                      "HolidayStartDate" +
                      props.HolidaySuffix
                  ]
                ).toLocaleDateString("af")
              }
            />
          </Col>
        </Row>
      </div>
    )
  );
}

export default HolidayOpenCloseTimes;
