import React, { useEffect, useState } from "react";

import { Row, Col } from "react-bootstrap";

import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { checkAccess } from "../../utils";
import MuiAlert from "@mui/material/Alert";

function EmergencyStateControls(props) {
  const [clinicClosed, setClinicClosed] = useState(false);
  const [drMobileTransfer, setDrMobileTransfer] = useState(false);
  const [divertSiteToggle, setDivertSiteToggle] = useState(false);
  const [clinicDivertSelected, setClinicDivertSelected] = useState(false);
  const [emergencyState, setEmergencyState] = useState(false);
  const [emergencyStateToSave, setEmergencyStateToSave] = useState("");

  const [e2Transfer, setE2Transfer] = useState("");
  const [e5Transfer, setE5Transfer] = useState("");

  const DistrictStaffAdminsRoles = [
    "CallFlowAreaStaff",
    "CallFlowDistrictStaff",
    "NexusAdmin",
    "MitelAdmin",
  ];

  const handleEmergencyStateChange = (e) => {
    setEmergencyState(!emergencyState);
    setDrMobileTransfer(null);
    setClinicClosed(null);
    setClinicDivertSelected(null);
    setDivertSiteToggle(null);

    if (!e.target.checked) {
      setEmergencyStateToSave("Normal");
    }
  };

  useEffect(() => {
    props.SetClinicData((ClinicData) => ({
      ...ClinicData,
      ...{
        EmergencyMode: emergencyStateToSave || props.ClinicData.EmergencyMode,
        E2Transfer: e2Transfer,
        E5Transfer: e5Transfer,
      },
    }));
  }, [emergencyStateToSave, e2Transfer, e5Transfer]);

  useEffect(() => {
    updateEmergencyMode();
  }, [props.ClinicData.Site]);

  const updateEmergencyMode = () => {
    if (props.ClinicData) {
      setEmergencyState(true);
      switch (props.ClinicData.EmergencyMode) {
        case "E1":
          setClinicClosed(false);
          setDrMobileTransfer("E1");
          break;
        case "E2":
          setClinicClosed(false);
          setDrMobileTransfer("E2");
          setE2Transfer(props.ClinicData.E2Transfer);
          break;
        case "E3":
          setClinicClosed(true);
          setDivertSiteToggle("E3");
          break;
        case "E4":
          setClinicClosed(true);
          setDivertSiteToggle("E4");
          setClinicDivertSelected("E4");
          break;
        case "E5":
          setClinicClosed(true);
          setDivertSiteToggle("E4");
          setClinicDivertSelected("E5");
          setE5Transfer(props.ClinicData.E5Transfer);
          break;
        default:
          setClinicClosed(false);
          setEmergencyState(false);
          break;
      }
    }
  };

  const EmergencySwitch = styled((props) => (
    <Switch
      checked={emergencyState}
      onChange={handleEmergencyStateChange}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiFormControlLabel": { float: "right" },
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <Col md={{ span: 4 }}>
      <Row style={{ marginTop: "15px" }}>
        <Col md={{ span: 12 }}>
          <FormControlLabel
            control={<EmergencySwitch sx={{ m: 1 }} />}
            label="Emergency Mode"
          />
        </Col>
      </Row>
      <Row>
        {emergencyState && (
          <Col md={{ span: 12 }} style={{ marginTop: "15px" }}>
            <FormControl style={{ width: "100%" }} variant="filled">
              <InputLabel id="site-closed">Will the site close?</InputLabel>
              <Select
                onChange={(e) => setClinicClosed(e.target.value)}
                labelId="site-closed-select-label"
                id="site-closed-select"
                label="Will the site close?"
                value={clinicClosed}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </Col>
        )}
      </Row>
      <Row>
        {emergencyState && clinicClosed === false && (
          <Col md={{ span: 12 }} style={{ marginTop: "15px" }}>
            <FormControl style={{ width: "100%" }} variant="filled">
              <InputLabel id="transfer-dr-mobile">
                Transfer to the DR mobile?
              </InputLabel>
              <Select
                onChange={(e) => {
                  setDrMobileTransfer(e.target.value);
                  setEmergencyStateToSave(e.target.value);
                }}
                labelId="transfer-dr-mobile-label"
                id="transfer-dr-mobile-select"
                label="Transfer to the DR mobile? DR NUMBER HERE"
                value={drMobileTransfer}
              >
                <MenuItem value={"E1"}>Yes</MenuItem>
                <MenuItem value={"E2"}>No</MenuItem>
              </Select>
            </FormControl>
          </Col>
        )}
      </Row>
      <Row>
        {emergencyState &&
          clinicClosed === false &&
          drMobileTransfer === "E1" && (
            <p style={{ marginTop: "15px" }}>
              The clinic will be diverted to the DR mobile when saved.
            </p>
          )}
      </Row>
      <Row>
        <Col md={{ span: 12 }}>
          {emergencyState === true &&
            clinicClosed === false &&
            drMobileTransfer === "E2" && (
              <TextField
                style={{ width: "100%", marginTop: "15px" }}
                id={props.ClinicName}
                type="number"
                label="Target Answer Point (Phone number)"
                variant="filled"
                defaultValue={e2Transfer}
                placeholder="Enter a phone number"
                onBlur={(e) => setE2Transfer(e.target.value)}
              />
            )}
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 12 }}>
          {clinicClosed === true && (
            <FormControl
              style={{ width: "100%", marginTop: "15px" }}
              variant="filled"
            >
              <InputLabel id="divert-site">
                Can the site be diverted?
              </InputLabel>
              <Select
                onChange={(e) => {
                  setDivertSiteToggle(e.target.value);
                  setEmergencyStateToSave(e.target.value);
                }}
                labelId="divert-site-label"
                id="divert-site-select"
                label="Can the site be diverted?"
                value={divertSiteToggle}
              >
                <MenuItem value={"E4"}>Yes</MenuItem>
                <MenuItem
                  disabled={!checkAccess(DistrictStaffAdminsRoles, props.user)}
                  value={"E3"}
                >
                  No
                </MenuItem>
              </Select>
            </FormControl>
          )}
        </Col>
      </Row>
      {clinicClosed && divertSiteToggle === "E3" && (
        <p style={{ marginTop: "15px" }}>
          The clinic closed message will be played to callers.
        </p>
      )}
      <Row>
        {clinicClosed && divertSiteToggle === "E4" && (
          <Col md={{ span: 12 }}>
            <FormControl
              style={{ width: "100%", marginTop: "15px" }}
              variant="filled"
            >
              <InputLabel id="divert-to-clinic">
                Divert to partner vets now site?
              </InputLabel>
              <Select
                onChange={(e) => {
                  setClinicDivertSelected(e.target.value);
                  setEmergencyStateToSave(e.target.value);
                }}
                labelId="divert-site-label"
                id="divert-site-select"
                label="Can the site be diverted?"
                value={clinicDivertSelected}
              >
                <MenuItem value={"E4"}>Yes</MenuItem>
                <MenuItem
                  disabled={!checkAccess(DistrictStaffAdminsRoles, props.user)}
                  value={"E5"}
                >
                  No
                </MenuItem>
              </Select>
            </FormControl>
          </Col>
        )}
      </Row>
      <Row>
        {clinicClosed &&
          divertSiteToggle === "E4" &&
          clinicDivertSelected === "E4" && (
            <p style={{ marginTop: "15px" }}>
              The clinic will be diverted to the partner site{" "}
              {props.ClinicData && props.ClinicData.partnerClinic}.
              <MuiAlert severity="info">
                Implementing a divert of telephony at this site, will not
                redirect other sites which are already diverted to this site.
                Their diverts also need to be changed.
              </MuiAlert>
            </p>
          )}
      </Row>
      <Row>
        {clinicClosed &&
          divertSiteToggle === "E4" &&
          clinicDivertSelected === "E5" && (
            <Col md={{ span: 12 }}>
              <TextField
                style={{ width: "100%", marginTop: "15px" }}
                label="Divert to another vets now location or company (Phone number)"
                variant="filled"
                type="number"
                defaultValue={e5Transfer}
                onBlur={(e) => setE5Transfer(e.target.value)}
              />
            </Col>
          )}
      </Row>
    </Col>
  );
}

export default EmergencyStateControls;
