import React, { useEffect, useState } from "react";
import AccessContainer from "../common/AccessContainer";
import Spinner from "react-bootstrap/Spinner";
import { Row, Col } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import MUIDataTable from "mui-datatables";
import useNotification from "../../hooks/sendNotification";
import sendAuditRecord from "../../hooks/createAuditRecord";
import {
  FormGroup,
  FormControl,
  FormControlLabel,
  Alert,
  Switch,
} from "@mui/material";

const parentColumns = [
  { name: "first_name", label: "First Name" },
  { name: "last_name", label: "Lastname" },
  {
    name: "custom_field",
    label: "Role",
    options: {
      customBodyRender: (item, meta) => item.cf_locum_type,
    },
  },
  {
    name: "custom_field",
    label: "Current VDS Proof",
    options: {
      customBodyRender: (item, meta) => {
        return item.cf_current_vds_proof !== "No"
          ? item.cf_current_vds_proof || "Not set"
          : "No";
      },
    },
  },
  {
    name: "custom_field",
    label: "Backshift Only or Restricted Hours Visa",
    options: {
      setCellProps: () => ({ style: { textAlign: "center" } }),
      customBodyRender: (item, meta) => {
        return item.cf_backshift === "Yes" ||
          item.cf_restricted_hours_visa === "Yes"
          ? "Yes"
          : "No";
      },
    },
  },
  { name: "mobile_number", label: "Mobile number" },
  { name: "email" },
  {
    name: "custom_field",
    label: "Locum Postcode",
    options: {
      customBodyRender: (item, meta) => item.cf_postcode,
    },
  },
  {
    name: "custom_field",
    label: "Primary District",
    options: {
      customBodyRender: (item, meta) => item.cf_primary_district,
    },
  },
  {
    name: "custom_field",
    label: "Secondary District",
    options: {
      customBodyRender: (item, meta) => item.cf_shift,
    },
  },
  {
    name: "custom_field",
    label: "Prefered Clinic",
    options: {
      customBodyRender: (item, meta) => item.cf_preferred_clinic,
      filter: false,
    },
  },
];

function FreshCRM(props) {
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [msg, sendNotification] = useNotification();
  const [auditRecord, createAuditRecord] = sendAuditRecord();
  const [selectedView, setSelectedView] = useState({
    name: "Verified Locums",
    viewId: 30001934561,
  });
  const freshViews = [
    { name: "Verified Locums", viewId: 30001934561 },

    { name: "Do not use", viewId: 30002110552 },
  ];

  useEffect(() => {
    if (selectedView) {
      setIsLoading(true);
      sendNotification({ msg: "Getting Locum bank data, please wait..." });
      fetch(`/api/FreshCRM/GetFreshCRMContacts/${selectedView.viewId}`)
        .then((response) => response.json())
        .then((items) => {
          setData(items);
          setCurrentData(items);
          setIsLoading(false);
          createAuditRecord({
            category: "LocumBank",
            action: "Locum Bank Accessed",
          });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [selectedView]);

  const hideVDS = (e) => {
    if (!e.target.checked) {
      setCurrentData(
        data.filter(
          (x) =>
            x.custom_field.cf_current_vds_proof !== null &&
            x.custom_field.cf_current_vds_proof !== "No"
        )
      );
    } else {
      setCurrentData(data);
    }
  };

  return (
    <AccessContainer
      customStyle={{ maxWidth: "100%", width: "100%" }}
      allowedRoles={["NexusAdmin", "LocumReport"]}
    >
      <Row style={{ marginBottom: 15 }}>
        <Col>
          <Row>
            <Col>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Select a report view
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {freshViews.map((view) => (
                    <Dropdown.Item
                      key={view.name}
                      value={view.viewId}
                      onClick={() => setSelectedView(view)}
                    >
                      {view.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col>
              <FormControl>
                <FormGroup>
                  <FormControlLabel
                    value="on"
                    control={
                      <Switch
                        color="primary"
                        onChange={hideVDS}
                        defaultChecked
                      />
                    }
                    label="Show expired VDS"
                    labelPlacement="start"
                  />
                </FormGroup>
              </FormControl>
            </Col>
          </Row>
        </Col>
        <Col>{isLoading && <Spinner animation="border" />}</Col>
        <Col className="pull-right d-none d-sm-block">
          <Alert severity="info">
            Use the magnifying icon below to search for clinics or any other
            details. This data is managed within{" "}
            <a
              href="https://vetsnowteam.myfreshworks.com/crm/sales/contacts/view/30001934561?per_page=25"
              target="_blank"
              rel="noopener noreferrer"
            >
              Freshsales
            </a>
            . Further guidance can be found here:{" "}
            <a
              href="https://we.ivc.group/vetsnow/knowledgehub/businessoperations/processes/shared/locumnetworkteamhandlinglocumdata.4.75eb0253176233d7ef0125f4.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </Alert>
        </Col>
      </Row>
      {data && (
        <MUIDataTable
          data={currentData}
          columns={parentColumns}
          title={`Locum Bank - ${selectedView.name}`}
          options={{
            selectableRows: "none",
            responsive: "simple",
            downloadOptions: {
              filename: `LocumDataExport-${new Date().toLocaleDateString()}.csv`,
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },
            onDownload: (buildHead, buildBody, columns, data) => {
              createAuditRecord({
                category: "LocumBank",
                action: "Locum Bank CSV downloaded",
              });
              return buildHead(columns) + buildBody(data);
            },
          }}
        />
      )}
    </AccessContainer>
  );
}
export default FreshCRM;
