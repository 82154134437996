import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { propsToClassKey } from "@mui/styles";

export default function ReportAddDialog({
  user,
  reportToAdd,
  setReportToAdd,
  reports,
}) {
  const [report, setReport] = useState("");
  const [reportError, setReportError] = useState(false);
  const [reportHelperText, setReportHelperText] = useState(null);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState(null);

  const [costCentres, setCostCentres] = useState([]);
  const [costCentreError, setCostCentreError] = useState(false);
  const [costCentreHelperText, setCostCentreHelperText] = useState(null);

  const handleYes = async () => {
    const data = {
      user: user,
      data: {
        email: email,
        costCentres: costCentres,
        report: report,
      },
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`/api/RLS/CreateCostCentreCodes/`, options);
      if (response.ok) {
        setReportToAdd(null);
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCodeChange = (e, selectedOptions) => {
    setCostCentres((prev) => {
      return [...selectedOptions];
    });
  };

  const handleNo = () => {
    setReportToAdd(null);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value.includes("@")) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const handleReportChange = (e) => {
    console.log(reports);
    if (reports.includes(e.target.value)) {
      setReportHelperText("Report name already exists");
      setReportError(true);
    } else {
      setReportError(false);
    }
    setReport(e.target.value);
  };

  return (
    <Dialog maxWidth="auto" open={!!reportToAdd}>
      <DialogTitle>Create new report security</DialogTitle>
      <DialogContent dividers>
        <TextField
          label="Report name"
          placeholder="The name of the report"
          value={report}
          onChange={handleReportChange}
          style={{ width: "100%" }}
          error={reportError}
          helperText={reportHelperText}
        />
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="Email Address"
          placeholder="Users Email"
          type="email"
          value={email}
          editable={false}
          fullWidth
          onChange={handleEmailChange}
          error={emailError}
          helperText={emailHelperText}
        />
        <Autocomplete
          sx={{ width: 600, paddingY: 1 }}
          multiple
          freeSolo
          id="costCentres"
          options={[]}
          value={costCentres}
          onChange={handleCodeChange}
          getOptionLabel={(option) => option}
          helperText={costCentreHelperText}
          error={costCentreError}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label="Cost Centre Codes"
              placeholder="Enter codes"
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNo}>Cancel</Button>
        <Button
          onClick={handleYes}
          disabled={!email || !costCentres.length || reportError}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
