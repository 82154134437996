import React, { useEffect } from "react";
import logo from "../../assets/default-monochrome-white.svg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Animal from "react-animals";

function PrivateHome({ toggleSideBar }) {
  useEffect(() => {
    toggleSideBar();
  }, []);

  return (
    <header className="App-header">
      <Container className="mt-3 mb-3 ">
        <img src={logo} className="App-logo" alt="logo" />{" "}
        <Row
          style={{
            marginTop: 45,
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Animal dance />
          <Animal dance />
          <Animal dance />
        </Row>
      </Container>
    </header>
  );
}
export default PrivateHome;
