import React, { useEffect, useState } from "react";
import {
  InputGroup,
  Button,
  FormControl,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import AccessContainer from "../common/AccessContainer";
//al.[AuditDate], al.UpdatedBy, al.[Action], ali.[Field], ali.[OldValue], ali.[NewValue]
const columns = [
  {
    name: "AuditDate",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => (
        <>
          {new Date(value).toLocaleDateString("en-GB")}{" "}
          {new Date(value).toLocaleTimeString()}
        </>
      ),
    },
  },
  { name: "UpdatedBy" },
  {
    name: "Action",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        switch (value) {
          case "C":
            return "Create";
          case "U":
            return "Update";
          case "D":
            return "Delete";
          default:
            return "N/A";
        }
      },
    },
  },
  { name: "Field" },
  { name: "OldValue" },
  { name: "NewValue" },
];

function AuditJournal(props) {
  const [data, setData] = useState([]);
  const [caseId, setCaseId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.caseId) {
      setCaseId(props.caseId);
      loadData(props.caseId);
    }
  }, [props.caseId]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      loadData(caseId);
    }
  };

  const loadData = (caseId) => {
    if (caseId) {
      setIsLoading(true);
      fetch(`/api/HelixAudit/CaseStatus/${caseId}`)
        .then((response) => response.json())
        .then((items) => {
          setData(items);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <AccessContainer allowedRoles={["NexusAdmin", "HelixAudit"]}>
      <Row>
        <Col md={4}>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Enter a Case Id"
              type="number"
              aria-label="Enter a Case Id"
              aria-describedby="button-Search"
              value={caseId}
              onChange={(e) => setCaseId(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <Button
              variant="primary"
              id="button-Search"
              onClick={() => {
                loadData(caseId);
              }}
            >
              Search
            </Button>
          </InputGroup>
        </Col>
        <Col> {isLoading && <Spinner animation="border" />}</Col>
      </Row>
      {data && (
        <Row>
          <MUIDataTable
            data={data}
            columns={columns}
            title={`Case ${caseId ? "- #" + caseId : ""} Status Audit`}
            options={{
              selectableRows: "none",
              responsive: "standard",
            }}
          />
        </Row>
      )}
    </AccessContainer>
  );
}
export default AuditJournal;
