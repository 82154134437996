import React from "react";

import TextField from "@mui/material/TextField";
import { Row, Col } from "react-bootstrap";

const convertTimeString = (date) => {
  return (
    padTo2Digits(new Date(date).getUTCHours()) +
    ":" +
    padTo2Digits(new Date(date).getUTCMinutes())
  );
};

const md = { span: 4 };
const xs = { span: 6 };

const padTo2Digits = (num) => num.toString().padStart(2, "0");

const handleTimeChange = (e, field, props) => {
  let updatedTime = props.ClinicData[field]
    ? new Date(props.ClinicData[field])
    : new Date(null);
  updatedTime.setUTCHours(
    e.target.value.split(":")[0],
    e.target.value.split(":")[1]
  );
  props.SetClinicData((ClinicData) => ({
    ...ClinicData,
    ...{ [field]: updatedTime.toISOString() },
  }));
};

function OpenCloseTimes(props) {
  return (
    props.ClinicData && (
      <div key={props.ClinicData.DNIS}>
        <Row>
          <Col md={{ span: 4 }}></Col>
          <Col style={{ fontWeight: "600" }} md={md} xs={xs}>
            Opening Time
          </Col>
          <Col style={{ fontWeight: "600" }} md={md} xs={xs}>
            Closing Time
          </Col>
        </Row>
        <Row>
          <Col md={md} style={{ padding: "15px", fontWeight: "600" }}>
            Monday:{" "}
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="CCScheduleMonOpen"
              variant="outlined"
              type="time"
              onChange={(e) => handleTimeChange(e, "CCScheduleMonOpen", props)}
              defaultValue={convertTimeString(
                props.ClinicData.CCScheduleMonOpen
              )}
            />
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="CCScheduleMonClosed"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(e, "CCScheduleMonClosed", props)
              }
              defaultValue={convertTimeString(
                props.ClinicData.CCScheduleMonClosed
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={md} style={{ padding: "15px", fontWeight: "600" }}>
            Tuesday:{" "}
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="CCScheduleTuesOpen"
              variant="outlined"
              type="time"
              onChange={(e) => handleTimeChange(e, "CCScheduleTuesOpen", props)}
              defaultValue={convertTimeString(
                props.ClinicData.CCScheduleTuesOpen
              )}
            />
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="CCScheduleTuesClosed"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(e, "CCScheduleTuesClosed", props)
              }
              defaultValue={convertTimeString(
                props.ClinicData.CCScheduleTuesClosed
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={md} style={{ padding: "15px", fontWeight: "600" }}>
            Wednesday:{" "}
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="CCScheduleWedOpen"
              variant="outlined"
              type="time"
              onChange={(e) => handleTimeChange(e, "CCScheduleWedOpen", props)}
              defaultValue={convertTimeString(
                props.ClinicData.CCScheduleWedOpen
              )}
            />
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="CCScheduleWedClosed"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(e, "CCScheduleWedClosed", props)
              }
              defaultValue={convertTimeString(
                props.ClinicData.CCScheduleWedClosed
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={md} style={{ padding: "15px", fontWeight: "600" }}>
            Thursday:{" "}
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="CCScheduleThurOpen"
              variant="outlined"
              type="time"
              onChange={(e) => handleTimeChange(e, "CCScheduleThurOpen", props)}
              defaultValue={convertTimeString(
                props.ClinicData.CCScheduleThurOpen
              )}
            />
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="CCScheduleThurClosed"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(e, "CCScheduleThurClosed", props)
              }
              defaultValue={convertTimeString(
                props.ClinicData.CCScheduleThurClosed
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={md} style={{ padding: "15px", fontWeight: "600" }}>
            Friday:{" "}
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="CCScheduleFriOpen"
              variant="outlined"
              type="time"
              onChange={(e) => handleTimeChange(e, "CCScheduleFriOpen", props)}
              defaultValue={convertTimeString(
                props.ClinicData.CCScheduleFriOpen
              )}
            />
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="CCScheduleFriClosed"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(e, "CCScheduleFriClosed", props)
              }
              defaultValue={convertTimeString(
                props.ClinicData.CCScheduleFriClosed
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={md} style={{ padding: "15px", fontWeight: "600" }}>
            Saturday:{" "}
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="CCScheduleSatOpen"
              variant="outlined"
              type="time"
              onChange={(e) => handleTimeChange(e, "CCScheduleSatOpen", props)}
              defaultValue={convertTimeString(
                props.ClinicData.CCScheduleSatOpen
              )}
            />
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="CCScheduleSatClosed"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(e, "CCScheduleSatClosed", props)
              }
              defaultValue={convertTimeString(
                props.ClinicData.CCScheduleSatClosed
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={md} style={{ padding: "15px", fontWeight: "600" }}>
            Sunday:{" "}
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="CCScheduleSunOpen"
              variant="outlined"
              type="time"
              onChange={(e) => handleTimeChange(e, "CCScheduleSunOpen", props)}
              defaultValue={convertTimeString(
                props.ClinicData.CCScheduleSunOpen
              )}
            />
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="CCScheduleSunClosed"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(e, "CCScheduleSunClosed", props)
              }
              defaultValue={convertTimeString(
                props.ClinicData.CCScheduleSunClosed
              )}
            />
          </Col>
        </Row>
      </div>
    )
  );
}

export default OpenCloseTimes;
