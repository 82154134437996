import React, { useEffect, useState } from "react";
import AuditLog from "./AuditLog";
import { Row, Col, Spinner } from "react-bootstrap";
import AccessContainer from "../common/AccessContainer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import Tooltip from "@mui/material/Tooltip";
import MUIDataTable from "mui-datatables";
import RowAddDialog from "./AddRowDialog";
import RowDeleteDialog from "./RowDeleteDialog";
import CostCentreCodes from "./CostCentreCodes";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function RLSCostCenterCodes(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [rowToDelete, setRowToDelete] = useState(false);
  const [rowToAdd, setRowToAdd] = useState(false);
  const [showAudit, setShowAudit] = useState(false);

  const columns = [
    {
      name: "email",
      label: "E-mail",
      editable: false,
      width: 200,
      disableClickEventBubbling: true,
    },
    {
      name: "costCentres",
      label: "Cost Centre Code",
      editable: true,
      with: 250,
      disableClickEventBubbling: true,
      options: {
        customBodyRender: (params, tableData) => {
          return (
            <CostCentreCodes
              params={params}
              tableData={tableData}
              rowToEdit={rowToEdit}
              setData={setData}
            />
          );
        },
      },
    },
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      name: "",
      type: "actions",
      sortable: false,
      disableClickEventBubbling: true,
      options: {
        customBodyRender: (params, tableData, x) => {
          const onClickDelete = (e) => {
            e.stopPropagation();
            setRowToDelete({
              email: tableData.rowData[0],
              costCentreCode: tableData.rowData[1],
              id: tableData.rowData[2],
            });
          };
          const onClickEdit = () => {
            const { rowIndex } = tableData;
            setRowToEdit(rowIndex);
          };
          const onClickSave = () => {
            const { rowIndex } = tableData;
            handleSave(rowIndex);
          };
          const onClickCancel = () => {
            setRowToEdit(null);
            window.location.reload();
          };
          return (
            <>
              {rowToEdit !== tableData.rowIndex ? (
                <Button onClick={onClickEdit}>
                  Edit
                  <EditIcon style={{ cursor: "pointer", margin: 4 }} />
                </Button>
              ) : (
                <>
                  <Tooltip title="save">
                    <SaveIcon
                      onClick={onClickSave}
                      style={{ cursor: "pointer", margin: 4, color: "green" }}
                    />
                  </Tooltip>
                  <Tooltip title="cancel">
                    <CancelIcon
                      onClick={onClickCancel}
                      style={{ cursor: "pointer", margin: 4, color: "orange" }}
                    />
                  </Tooltip>
                  <Tooltip title="delete">
                    <DeleteIcon
                      onClick={onClickDelete}
                      style={{ cursor: "pointer", margin: 4, color: "red" }}
                    />
                  </Tooltip>
                </>
              )}
            </>
          );
        },
      },
    },
  ];

  const handleSave = async (rowIndex) => {
    const result = window.confirm(
      "Are you sure you would like to save your changes?"
    );
    if (!result) {
      window.location.reload();
      return;
    }
    const rowToSave = data[rowIndex];

    const dataAPI = {
      user: props.user,
      rowId: rowToSave.id,
      data: rowToSave,
      report: props.report,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataAPI),
    };
    try {
      setIsLoading(true);
      const response = await fetch(`/api/RLS/UpdateCostCentreCodes/`, options);
      if (response.ok) {
        setIsLoading(false);
        setRowToEdit(null);
        loadData();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const loadData = () => {
    if (props.user.userDetails) {
      setIsLoading(true);
      fetch(
        `/api/RLS/GetCostCentreCodes/${props.user.userDetails}/${props.report}`
      )
        .then((response) => response.json())
        .then((items) => {
          setData(items);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      console.log("No user email");
    }
  };

  useEffect(() => {
    if (props.user.userDetails) {
      loadData(props.user.userDetails);
    }
  }, []);

  const CustomFooter = () => {
    return (
      <ButtonGroup
        variant="outlined"
        // aria-label="outlined primary button group"
      >
        <Button
          onClick={() => {
            setShowAudit(!showAudit);
          }}
        >
          View Audit
        </Button>
        <Button>
          <a
            href="https://servicedesk.vets-now.com/a/solutions/articles/50000162242"
            target="_blank"
            rel="noopener noreferrer"
          >
            Help
          </a>
        </Button>
      </ButtonGroup>
    );
  };

  const CreateButton = () => {
    return (
      <Button onClick={() => setRowToAdd(true)}>
        Create new <AddIcon />
      </Button>
    );
  };

  return (
    <AccessContainer allowedRoles={["NexusAdmin", "HelixAudit", "Finance"]}>
      {!!rowToDelete && (
        <RowDeleteDialog
          setIsLoading={setIsLoading}
          loadData={loadData}
          user={props.user}
          rowToDelete={rowToDelete}
          setRowToDelete={setRowToDelete}
          report={props.report}
        />
      )}
      {!!rowToAdd && (
        <RowAddDialog
          setIsLoading={setIsLoading}
          setRowToAdd={setRowToAdd}
          loadData={loadData}
          rowToAdd={rowToAdd}
          user={props.user}
          data={data}
          report={props.report}
        />
      )}
      {!!showAudit && (
        <AuditLog
          showAudit={showAudit}
          setShowAudit={setShowAudit}
          report={props.report}
        />
      )}
      <Row>
        <Col> {isLoading && <Spinner animation="border" />}</Col>
      </Row>
      {data && (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button href="/ReportSecurity/">
              <ArrowBackIcon /> Back
            </Button>
            <CreateButton />
          </div>
          <Row>
            <MUIDataTable
              data={data}
              columns={columns}
              title={`Row Level Security (${decodeURIComponent(props.report)})`}
              options={{
                selectableRows: "none",
              }}
            />
          </Row>
        </>
      )}
      <CustomFooter />
    </AccessContainer>
  );
}
export default RLSCostCenterCodes;
