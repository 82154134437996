import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";

export default function RowAddDialog({
  setIsLoading,
  setRowToAdd,
  loadData,
  rowToAdd,
  user,
  data,
  report,
}) {
  const [email, setEmail] = useState("");
  const [costCentres, setCostCentres] = useState([]);
  const [error, setError] = useState(false);

  const handleYes = async () => {
    const data = {
      user: user,
      data: {
        email: email,
        costCentres: costCentres,
        report: report,
      },
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    try {
      setIsLoading(true);
      const response = await fetch(`/api/RLS/CreateCostCentreCodes/`, options);
      if (response.ok) {
        setRowToAdd(null);
        setIsLoading(false);
        loadData();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleNo = () => {
    setRowToAdd(null);
  };

  const handleChange = (e, i) => {
    const { id, value, type, checked } = e.target;
    if (type === "email") {
      setEmail(value);
      if (value.includes("@")) {
        setError(false);
      } else {
        setError(true);
      }
    } else if (id === "costCentres") {
      setCostCentres(i);
    }
  };

  return (
    <Dialog maxWidth="auto" open={!!rowToAdd}>
      <DialogTitle>Provide new RLS</DialogTitle>
      <DialogContent dividers>
        <TextField
          value={report}
          label="Report"
          disabled
          fullWidth
          margin="dense"
        />
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="Email Address"
          placeholder="Users Email"
          type="email"
          value={email}
          editable={false}
          fullWidth
          onChange={handleChange}
          error={error}
          helperText={error ? "Email must contain an @ symbol" : ""}
        />
        <Autocomplete
          margin="dense"
          fullWidth
          multiple
          freeSolo
          id="costCentres"
          options={[]}
          value={costCentres}
          onChange={handleChange}
          getOptionLabel={(option) => option}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label="Cost Centre Codes"
              placeholder="Enter codes"
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNo}>Cancel</Button>
        <Button
          onClick={handleYes}
          disabled={!email || !costCentres.length || error}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
