import React from "react";
import { Navbar, Nav, Button, Image } from "react-bootstrap";
import logo from "../../assets/logo-white.png";
import vnLogo from "../../assets/vn-logo.svg";

function Navigation({ user, logoutUser, toggleSideBar }) {
  const providers = ["aad"];
  const redirect = window.location.href;

  return (
    <Navbar className="navbar-themed" expand="lg">
      <Navbar.Brand href="/" style={{ marginLeft: 75 }}>
        <Image src={logo} style={{ maxHeight: 25 }} fluid /> Nexus
        <Image
          src={vnLogo}
          alt="Vets Now Logo"
          style={{ maxHeight: 30, marginLeft: "15px" }}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          {/* <Nav.Link href="/">Home</Nav.Link> */}
        </Nav>
        <Nav style={{ marginRight: 25 }}>
          <Navbar.Text>
            {!user &&
              providers.map((provider) => (
                <Button
                  key="login"
                  variant="outline-primary"
                  size="sm"
                  href={`/.auth/login/${provider}?post_login_redirect_uri=${redirect}`}
                >
                  Login
                </Button>
              ))}
            {user && (
              <>
                <span>{user && user?.userDetails}</span>{" "}
                <Button
                  key="logout"
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => {
                    logoutUser();
                  }}
                  href={`/.auth/logout?post_logout_redirect_uri=/`}
                >
                  Logout
                </Button>
              </>
            )}
          </Navbar.Text>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
export default Navigation;
