import React from "react";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

function HolidayDropdown(props) {
  return (
    <>
        <FormControl style={{ width: "100%" }} variant="filled">
          <InputLabel id="holiday-single-select-label">Holiday:</InputLabel>
          <Select
            labelId="holiday-single-select-label"
            id="holiday-single-select"
            label="Holiday"
            onChange={(e) => {props.handleHolidaySelect(e.target.value)}}
          >
              <MenuItem key={"1"} value={"1"}>
                Good Friday
              </MenuItem>
              <MenuItem key="2" value="2">
                Easter Monday
              </MenuItem>
              <MenuItem key="3" value="3">
                Early May Bank Holiday
              </MenuItem>
              <MenuItem key="4" value="4">
                Spring Bank Holiday
              </MenuItem>
              <MenuItem key="5" value="5">
                Summer Bank Holiday
              </MenuItem>
              <MenuItem key="6" value="6">
                Christmas Eve
              </MenuItem>
              <MenuItem key="7" value="7">
                Christmas
              </MenuItem>
              <MenuItem key="8" value="8">
                Boxing Day
              </MenuItem>
              <MenuItem key="9" value="9">
                New Years Eve
              </MenuItem>
              <MenuItem key="10" value="10">
                New Years Day
              </MenuItem>
              <MenuItem key="11" value="11">
                Scottish Holiday
              </MenuItem>
              <MenuItem key="12" value="12">
                St Patricks Day
              </MenuItem>
              <MenuItem key="13" value="13">
                Battle of the Boyne
              </MenuItem>
          </Select>
        </FormControl>
    </>
  );
}

export default HolidayDropdown;
