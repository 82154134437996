import React from "react";
import { Navbar, Nav, NavItem, NavLink } from "react-bootstrap";

function Footer() {
  return (
    <footer className="footer">
      <Navbar className="navbar-themed d-flex justify-content-center">
        <Nav>
          <NavItem>
            <NavLink href="https://we.ivc.group">Intranet</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://helix.vets-now.com">Helix</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://vetsnoweu.freshservice.com/support/home">
              Service Desk
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://vetsnow.cascadecloud.co.uk/">
              HR Portal(Cascade)
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://vetsnow.crm11.dynamics.com/">Hive</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://horos.vets-now.com/EmployeeOnlineHealth/LIVE/">
              Horos
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://vets-now.com">
              © {new Date().getFullYear()} Vets Now Ltd
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://nexus.vets-now.com/">Nexus A</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://witty-smoke-0ae89f210.5.azurestaticapps.net/">
              Nexus B
            </NavLink>
          </NavItem>
        </Nav>
      </Navbar>
    </footer>
  );
}

export default Footer;
