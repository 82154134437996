import React, { useEffect, useState } from "react";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Col } from "react-bootstrap";

const messages = [
  "Message1",
  "Message2",
  "Message3",
  "Message4",
  "Message5",
  "Message6",
  "Message7",
  "Message8",
  "Message9",
  "Message10",
  "Message11",
  "Message12",
  "Message13",
  "Message14",
  "Message15",
  "QueueMessage1",
  "QueueMessage2",
  "QueueMessage3",
  "QueueMessage4",
  "QueueMessage5",
];

const audioFiles = [
  "Message1.wav",
  "Message2.wav",
  "Message3.wav",
  "Message4.wav",
  "Message5.wav",
  "Message6.wav",
  "Message7.wav",
  "Message8.wav",
  "Message9.wav",
  "Message10.wav",
  "Message11.wav",
  "Message12.wav",
  "Message13.wav",
  "Message14.wav",
  "Message15.wav",
];

function Messages(props) {

  const [mitelMessage, setMitelMessage] = useState(null);
  const [mitelAudio, setMitelAudio] = useState(null);


  useEffect(() => {
    fetch(`/api/CallFlow/GetMitelMessages/`)
      .then((response) => response.json())
      .then((items) => {
        setMitelMessage(items);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(mitelMessage)

  useEffect(() => {
    console.log(props.Message, props.Audio)

    props.SetClinicData((ClinicData) => ({
      ...ClinicData,
      ...{
        [props.Message]: props.Audio
      },
    }));
  },[props.Audio]);

  const handleMessageSelect = (event) => {
    fetch(`/api/CallFlow/GetMitelAudioFiles/`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(event),
    })
      .then((response) => response.json())
      .then((items) => {
        setMitelAudio(items);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Col md={{ span: 6 }}>
        <FormControl style={{ width: "100%" }} variant="filled">
          <InputLabel id="contact-centre-message-select-label">
            Message:
          </InputLabel>
          <Select
            labelId="contact-centre-message-select"
            id="contact-centre-message-select"
            label="Message"
            value={props.Message}
            onChange={(e) => {
              props.SetMessage(e.target.value);
              handleMessageSelect(e.target.value);
            }}
          >
            {mitelMessage && mitelMessage.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Col>
      <Col md={{ span: 6 }}>
        {props.Message && (
          <FormControl style={{ width: "100%" }} variant="filled">
            <InputLabel id="contact-centre-audio-select-label">
              Audio File:
            </InputLabel>
            <Select
              labelId="contact-centre-audio-select"
              id="contact-centre-audio-select"
              label="Audio File"
              onChange={(e) => {
                props.SetAudio(e.target.value);
              }}
            >
              <MenuItem key="NULL" value="NULL">None</MenuItem>
              {mitelAudio && mitelAudio.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Col>
    </>
  );
}

export default Messages;