import React from "react";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { checkAccess } from "../../utils";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";

function AccessContainer(props) {
  const [user, setUser] = useSessionStorage("user", null);
  return checkAccess(props.allowedRoles, user) ? (
    <Container
      style={props.customStyle && props.customStyle}
      className="mt-3 mb-3"
    >
      {props.children}
    </Container>
  ) : (
    <Container>
      <Alert className="mt-3" variant="danger">
        You do not have access to this page.
      </Alert>
    </Container>
  );
}
export default AccessContainer;
