import React from "react";

import { checkAccess } from "../../utils";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

function DepartmentDropdown(props) {
  return (
    <FormControl style={{ width: "100%" }} variant="filled">
      <InputLabel id="department-select-label">Department:</InputLabel>
      <Select
        labelId="department-select-label"
        id="department-select"
        label="Department"
        onChange={(e) => {
          props.setDepartment(e.target.value);
          props.setClinicName([]);
          props.handleDepartmentChange(e.target.value);
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem
          disabled={
            !checkAccess(
              [
                "CallFlowAreaStaff",
                "CallFlowDistrictStaff",
                "CallFlowClinicStaff",
                "MitelAdmin",
                "NexusAdmin",
              ],
              props.user
            )
          }
          value={"clinic"}
        >
          Clinic
        </MenuItem>
        <MenuItem
          disabled={!checkAccess(["MitelAdmin", "NexusAdmin"], props.user)}
          value={"contact-centre"}
        >
          Contact Centre
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default DepartmentDropdown;
