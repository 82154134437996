import React, { useEffect, useState } from "react";

import { Row, Col } from "react-bootstrap";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";

import AccessContainer from "../common/AccessContainer";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import DepartmentDropdown from "./DepartmentDropdown";
import ClinicalOpenCloseTimes from "./OpenCloseTimes";
import CCOpenCloseTimes from "./CCOpenCloseTimes";
import ClinicDropdown from "./ClinicDropdown";
import EmergencyStateControls from "./EmergencyStateControls";
import Messages from "./Messages";
import { checkAccess } from "../../utils";

function CallFlowLayout(props) {
  const [clinicName, setClinicName] = useState([]);
  const [department, setDepartment] = useState(null);
  const [ccUpdateFields, setCcUpdateFields] = useState(null);
  const [message, setMessage] = useState(null);
  const [audio, setAudio] = useState(null);
  const [clinics, setClinics] = useState([]);
  const [clinicData, setClinicData] = useState(null);
  const [user, setUser] = useSessionStorage("user", null);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    fetch(`/api/CallFlow/GetClinicsMitel/`)
      .then((response) => response.json())
      .then((items) => {
        setClinics(items);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDepartmentChange = () => {
    console.log(department);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClinicSelect = (event) => {
    const {
      target: { value },
    } = event;
    setClinicName(typeof value === "string" ? value.split(",") : value);
    console.log(value);
    fetch(`/api/CallFlow/GetSingleRowForClinicsMitel/`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(value),
    })
      .then((response) => {
        setCcUpdateFields(null);
        return response.json();
      })
      .then((items) => {
        console.log(items);
        setClinicData(items);
      })
      .catch((err) => {
        setCcUpdateFields(null);
        console.log(err);
      });
  };

  const handleResetToDefault = () => {
    if (
      window.confirm(
        "Are you sure you want to revert the selected clinic to the default values?"
      )
    ) {
      fetch(`/api/CallFlow/UpdateClinicMitelResetToDefault/`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          user: user.userDetails,
        },
        method: "POST",
        body: JSON.stringify(clinicData),
      })
        .then((response) => response.json())
        .then((items) => {
          setClinicData(items);
        })
        .catch((err) => {
          console.log(err);
        });

      setOpen(true);
    }
  };

  const handleSaveClinic = (event) => {
    const reqBody =
      department === "clinic"
        ? clinicData
        : { data: ccUpdateFields, sites: clinicName };
    const url =
      department === "clinic"
        ? "/api/CallFlow/UpdateClinicMitel/"
        : "/api/CallFlow/UpdateContactCentreMitel/";
    if (window.confirm("Are you sure you want to save?")) {
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          user: user.userDetails,
        },
        method: "POST",
        body: JSON.stringify(reqBody),
      })
        .then((response) => response.json())
        .then((items) => {
          setClinicData((clinicData) => ({
            ...clinicData,
            ...{ ModifiedBy: user.userDetails, ModifiedTime: new Date() },
          }));
          console.log(items);
        })
        .catch((err) => {
          console.log(err);
        });

      setOpen(true);
    }
  };
  return (
    <AccessContainer
      allowedRoles={[
        "NexusAdmin",
        "MitelAdmin",
        "CallFlowAreaStaff",
        "CallFlowDistrictStaff",
        "CallFlowClinicStaff",
      ]}
    >
      {Intl.DateTimeFormat().resolvedOptions().timeZone != "Europe/London" && (
        <MuiAlert severity="warning" style={{ marginBottom: 10 }}>
          Your current timezone is:{" "}
          {Intl.DateTimeFormat().resolvedOptions().timeZone} Times need to be
          updated to UTC
        </MuiAlert>
      )}
      {!checkAccess(
        [
          "NexusAdmin",
          "MitelAdmin",
          "CallFlowAreaStaff",
          "CallFlowDistrictStaff",
        ],
        user
      ) && (
        <MuiAlert severity="warning" style={{ marginBottom: 10 }}>
          Specific call flow paths are disabled based on your job role
        </MuiAlert>
      )}
      <Row style={{ minHeight: "75vh" }}>
        <Col md={{ span: 8 }}>
          <Row style={{ rowGap: 15 }}>
            <Col md={{ span: 6 }}>
              <DepartmentDropdown
                handleDepartmentChange={handleDepartmentChange}
                setClinicName={setClinicName}
                setDepartment={setDepartment}
                setMessage={setMessage}
                user={user}
              />
            </Col>
            <Col md={{ span: 6 }}>
              <ClinicDropdown
                handleClinicSelect={handleClinicSelect}
                Clinics={clinics}
                ClinicName={clinicName}
                Department={department}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            {department === "contact-centre" && clinicName.length > 0 && (
              <Messages
                ClinicData={clinicData}
                SetClinicData={setCcUpdateFields}
                SetMessage={setMessage}
                Message={message}
                SetAudio={setAudio}
                Audio={audio}
              />
            )}
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <Col md={{ span: 12 }}>
              {department === "contact-centre" && (
                <CCOpenCloseTimes
                  ClinicData={clinicData}
                  SetClinicData={setCcUpdateFields}
                />
              )}
              {department === "clinic" && (
                <ClinicalOpenCloseTimes
                  ClinicData={clinicData}
                  SetClinicData={setClinicData}
                />
              )}
            </Col>
          </Row>
        </Col>
        {department === "clinic" && clinicData && (
          <EmergencyStateControls
            ClinicData={clinicData}
            Clinics={clinics}
            clinicName={clinicName}
            SetClinicData={setClinicData}
            user={user}
          />
        )}
      </Row>
      <Row style={{ marginTop: "15px", rowGap: "15px", marginBottom: 15 }}>
        <Col md={{ span: 6 }}>
          {clinicData && clinicData.ModifiedBy && (
            <MuiAlert severity="info">
              Last updated by {clinicData.ModifiedBy} @{" "}
              {new Date(clinicData.ModifiedTime).toLocaleString("en-GB")}
            </MuiAlert>
          )}
        </Col>
        <Col md={{ span: 6 }}>
          {clinicData && clinicData.IsDefault === 0 && (
            <Button onClick={handleResetToDefault} variant="contained">
              Reset to Default
            </Button>
          )}
          {clinicData && (
            <Button
              onClick={handleSaveClinic}
              style={{ marginLeft: 15 }}
              variant="outlined"
            >
              Save
            </Button>
          )}
        </Col>
      </Row>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Succesfully saved this record
        </Alert>
      </Snackbar>
    </AccessContainer>
  );
}
export default CallFlowLayout;
