import React, { useEffect, useState } from "react";
import AccessContainer from "../common/AccessContainer";

function EnhancedSearch(props) {
  return (
    <AccessContainer
      customStyle={{ maxWidth: "100%", width: "100%" }}
      allowedRoles={["NexusAdmin", "HelixAudit"]}
    >
      <iframe
        style={{ minHeight: "100vh" }}
        width="100%"
        height="100%"
        title="enhancedSearch"
        seamless
        src={process.env.REACT_APP_ENHANCED_SEARCH_URL}
      />
    </AccessContainer>
  );
}
export default EnhancedSearch;
