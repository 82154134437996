import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Button } from "react-bootstrap";

export default function RowDeleteDialog({
  user,
  rowToDelete,
  setIsLoading,
  setRowToDelete,
  loadData,
  report,
}) {
  const handleYes = async () => {
    const data = {
      user: user,
      rowId: rowToDelete.id,
      data: rowToDelete,
      report: report,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    try {
      setIsLoading(true);
      const response = await fetch(`/api/RLS/DeleteCostCentreCodes/`, options);
      if (response.ok) {
        setRowToDelete(null);
        setIsLoading(false);
        loadData();
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleNo = () => {
    setRowToDelete(null);
  };
  return (
    <Dialog maxWidth="xs" open={!!rowToDelete}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent dividers>
        {`Pressing 'Yes' will delete cost centre code: ${rowToDelete.costCentreCode}.`}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNo}>No</Button>
        <Button onClick={handleYes}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
}
