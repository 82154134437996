import React, { useEffect, useState } from "react";
import AccessContainer from "../common/AccessContainer";
import ListItem from "@material-ui/core/ListItem";
import { Row, Col, Spinner } from "react-bootstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ReportAddDialog from "./AddReportDialog";

const ListLink = ({ report }) => {
  return (
    <ListItem
      button
      key="CaseStatus"
      component="a"
      href={`/ReportSecurity/${report}`}
    >
      {report}
    </ListItem>
  );
};

export default function ReportSecurity(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [reportToAdd, setReportToAdd] = useState(false);

  const loadData = () => {
    if (props.user.userDetails) {
      setIsLoading(true);
      fetch(`/api/RLS/GetCostCentreCodes/${props.user.userDetails}/all`)
        .then((response) => response.json())
        .then((items) => {
          setReports(items);
          console.log("reports: " + items);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      console.log("No user email");
    }
  };

  useEffect(() => {
    if (props.user.userDetails) {
      loadData(props.user.userDetails);
    }
  }, []);

  const CreateButton = () => {
    return (
      <Button onClick={() => setReportToAdd(true)}>
        Create new <AddIcon />
      </Button>
    );
  };

  return (
    <AccessContainer allowedRoles={["NexusAdmin", "HelixFinance"]}>
      {isLoading && <Spinner animation="border" />}
      {!!reportToAdd && (
        <ReportAddDialog
          user={props.user}
          reportToAdd={reportToAdd}
          reports={reports}
          setReportToAdd={setReportToAdd}
        />
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1>Report Security</h1>
        <CreateButton />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Report name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map((report, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <ListLink report={report} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </AccessContainer>
  );
}
