import React, { useEffect, useState } from "react";
import AccessContainer from "../../common/AccessContainer";
import {
  Card,
  FormText,
  Row,
  Col,
  Spinner,
  Modal,
  Form,
} from "react-bootstrap";
import ImportResult from "../PaymentImport/ImportResult";
import ImportLog from "../ImportLog";
import Papa from "papaparse";
import useNotification from "../../../hooks/sendNotification";

function PaymentImport(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [msg, sendNotification] = useNotification();
  const [uploadCount, setUploadCount] = useState(0);

  useEffect(() => {}, []);

  const handlePaymentsImportChange = (file) => {
    setIsLoading(true);
    Papa.parse(file, {
      skipEmptyLines: "greedy",
      header: false,
      complete: (json) => {
        setIsLoading(false);
        setModalShow(true);
        setModalContent(
          <ImportResult
            data={json}
            uploadCount={uploadCount}
            setUpload={setUploadCount}
            file={file}
          />
        );
      },
      error: (ex, file) => {
        sendNotification({
          msg: "ERROR:" + ex,
          variant: "danger",
        });
      },
    });
  };

  return (
    <AccessContainer allowedRoles={["HelixFinance", "NexusAdmin"]}>
      {isLoading && <Spinner animation="border" />}
      <Card style={{ width: "50rem" }} centered="true">
        <Card.Header>
          <h4>Helix Payments Import &amp; Reconciliation</h4>
          <FormText color="muted">
            A guide on usage can be found{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://vetsnoweu.freshservice.com/a/solutions/articles/50000059784"
            >
              here
            </a>
            . <br />
            <br />
            Select an import file to begin.
          </FormText>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md="6">
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Control
                  type="file"
                  name="paymentsImportFile"
                  accept=".csv"
                  onChange={(e) => {
                    handlePaymentsImportChange(e.target.files[0]);
                    e.target.value = null;
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Body>
          <ImportLog
            uploadCount={uploadCount}
            category="HELIX_PAYMENT_IMPORT"
          />
        </Card.Body>
      </Card>
      <Modal
        size="xxl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Helix Payment Import
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
      </Modal>
    </AccessContainer>
  );
}
export default PaymentImport;
