import React from "react";

import TextField from "@mui/material/TextField";
import { Row, Col } from "react-bootstrap";

const convertTimeString = (date) => {
  return (
    padTo2Digits(new Date(date).getUTCHours()) +
    ":" +
    padTo2Digits(new Date(date).getUTCMinutes())
  );
};

const padTo2Digits = (num) => num.toString().padStart(2, "0");

const md = { span: 4 };
const xs = { span: 6 };

const handleTimeChange = (e, field, props) => {
  let updatedTime = new Date(props.ClinicData[field]);
  updatedTime.setUTCHours(
    e.target.value.split(":")[0],
    e.target.value.split(":")[1]
  );
  props.SetClinicData((ClinicData) => ({
    ...ClinicData,
    ...{ [field]: updatedTime.toISOString() },
  }));
};

function OpenCloseTimes(props) {
  return (
    props.ClinicData && (
      <div key={props.ClinicData.DNIS}>
        <Row>
          <Col md={md}></Col>
          <Col style={{ fontWeight: "600" }} md={md} xs={xs}>
            Shift End
          </Col>
          <Col style={{ fontWeight: "600" }} md={md} xs={xs}>
            Shift Start
          </Col>
        </Row>
        <Row>
          <Col
            md={md}
            xs={{ span: 12 }}
            style={{ padding: "15px", fontWeight: "600" }}
          >
            Monday:{" "}
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="ClinicalScheduleMonOvernightClosed"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(e, "ClinicalScheduleMonOvernightClosed", props)
              }
              defaultValue={convertTimeString(
                props.ClinicData.ClinicalScheduleMonOvernightClosed
              )}
            />
          </Col>

          <Col md={md} xs={xs}>
            <TextField
              id="ClinicalScheduleMonOpen"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(e, "ClinicalScheduleMonOpen", props)
              }
              defaultValue={convertTimeString(
                props.ClinicData.ClinicalScheduleMonOpen
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col
            md={{ span: 4 }}
            xs={{ span: 12 }}
            style={{ padding: "15px", fontWeight: "600" }}
          >
            Tuesday:{" "}
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="ClinicalScheduleTuesOvernightClosed"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(
                  e,
                  "ClinicalScheduleTuesOvernightClosed",
                  props
                )
              }
              defaultValue={convertTimeString(
                props.ClinicData.ClinicalScheduleTuesOvernightClosed
              )}
            />
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="ClinicalScheduleTuesOpen"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(e, "ClinicalScheduleTuesOpen", props)
              }
              defaultValue={convertTimeString(
                props.ClinicData.ClinicalScheduleTuesOpen
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col
            md={{ span: 4 }}
            xs={{ span: 12 }}
            style={{ padding: "15px", fontWeight: "600" }}
          >
            Wednesday:{" "}
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="ClinicalScheduleWedOvernightClosed"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(e, "ClinicalScheduleWedOvernightClosed", props)
              }
              defaultValue={convertTimeString(
                props.ClinicData.ClinicalScheduleWedOvernightClosed
              )}
            />
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="ClinicalScheduleWedOpen"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(e, "ClinicalScheduleWedOpen", props)
              }
              defaultValue={convertTimeString(
                props.ClinicData.ClinicalScheduleWedOpen
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col
            md={{ span: 4 }}
            xs={{ span: 12 }}
            style={{ padding: "15px", fontWeight: "600" }}
          >
            Thursday:{" "}
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="ClinicalScheduleThurOvernightClosed"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(
                  e,
                  "ClinicalScheduleThurOvernightClosed",
                  props
                )
              }
              defaultValue={convertTimeString(
                props.ClinicData.ClinicalScheduleThurOvernightClosed
              )}
            />
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="ClinicalScheduleThurOpen"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(e, "ClinicalScheduleThurOpen", props)
              }
              defaultValue={convertTimeString(
                props.ClinicData.ClinicalScheduleThurOpen
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col
            md={{ span: 4 }}
            xs={{ span: 12 }}
            style={{ padding: "15px", fontWeight: "600" }}
          >
            Friday:{" "}
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="ClinicalScheduleFriOvernightClosed"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(e, "ClinicalScheduleFriOvernightClosed", props)
              }
              defaultValue={convertTimeString(
                props.ClinicData.ClinicalScheduleFriOvernightClosed
              )}
            />
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="ClinicalScheduleFriOpen"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(e, "ClinicalScheduleFriOpen", props)
              }
              defaultValue={convertTimeString(
                props.ClinicData.ClinicalScheduleFriOpen
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col
            md={{ span: 4 }}
            xs={{ span: 12 }}
            style={{ padding: "15px", fontWeight: "600" }}
          >
            Saturday:{" "}
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="ClinicalScheduleSatOvernightClosed"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(e, "ClinicalScheduleSatOvernightClosed", props)
              }
              defaultValue={convertTimeString(
                props.ClinicData.ClinicalScheduleSatOvernightClosed
              )}
            />
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="ClinicalScheduleSatOpen"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(e, "ClinicalScheduleSatOpen", props)
              }
              defaultValue={convertTimeString(
                props.ClinicData.ClinicalScheduleSatOpen
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col
            md={{ span: 4 }}
            xs={{ span: 12 }}
            style={{ padding: "15px", fontWeight: "600" }}
          >
            Sunday:{" "}
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="ClinicalScheduleSunOvernightClosed"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(e, "ClinicalScheduleSunOvernightClosed", props)
              }
              defaultValue={convertTimeString(
                props.ClinicData.ClinicalScheduleSunOvernightClosed
              )}
            />
          </Col>
          <Col md={md} xs={xs}>
            <TextField
              id="ClinicalScheduleSunOpen"
              variant="outlined"
              type="time"
              onChange={(e) =>
                handleTimeChange(e, "ClinicalScheduleSunOpen", props)
              }
              defaultValue={convertTimeString(
                props.ClinicData.ClinicalScheduleSunOpen
              )}
            />
          </Col>
        </Row>
      </div>
    )
  );
}

export default OpenCloseTimes;
