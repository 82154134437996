import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function CostCentreCodes({
  params,
  tableData,
  rowToEdit,
  setData,
}) {
  const { rowIndex } = tableData;
  const handleChange = (e, selectedOptions) => {
    setData((prev) => {
      const updatedData = [...prev];
      updatedData[rowIndex].costCentres = selectedOptions;
      return updatedData;
    });
  };

  return (
    <>
      <Autocomplete
        sx={{ width: 600 }}
        multiple
        freeSolo
        id="tags-outlined"
        options={[]}
        value={params}
        onChange={handleChange}
        disabled={rowIndex === rowToEdit ? false : true}
        getOptionLabel={(option) => option}
        defaultValue={params && params.sort()}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Cost Centre Codes"
            placeholder="Enter New Code"
          />
        )}
      />
    </>
  );
}
