import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import { Table, Paper } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { Spinner } from "react-bootstrap";

function AuditLog({ showAudit, setShowAudit, report }) {
  const [auditData, setAuditData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: "action",
      label: "action",
      editable: false,
      width: 200,
      disableClickEventBubbling: true,
    },
    {
      name: "userDetails",
      label: "User",
      editable: true,
      width: 250,
      disableClickEventBubbling: true,
    },
    {
      name: "date",
      label: "Date",
    },
    {
      name: "details",
      label: "Details",
      maxWidth: 20,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await fetch(`/api/RLS/GetRLSAuditLogs/${report}`);
        const json = await res.json();
        console.log("JSON res: " + json);
        const reformattedData = json.map((row) => {
          return {
            action: row.action,
            description: row.description,
            userDetails: row.user.userDetails,
            date: new Date(row._ts * 1000).toDateString(),
            details: () => {
              let details = [];
              for (const [key, value] of Object.entries(row.description)) {
                details.push(` ${key}: ${value},   `);
              }
              return details;
            },
          };
        });
        setAuditData(reformattedData);
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleClose = () => {
    setShowAudit(false);
  };

  return (
    <Dialog maxWidth="xl" minWidth="xl" open={showAudit} onClose={handleClose}>
      {loading && <Spinner />}
      <Button onClick={handleClose}>
        <CloseIcon />
      </Button>
      <Table component={Paper}>
        <MUIDataTable
          data={auditData}
          columns={columns}
          title="Audit Logs"
          options={{
            selectableRows: "none",
          }}
        />
      </Table>
      <DialogActions></DialogActions>
    </Dialog>
  );
}

export default AuditLog;
