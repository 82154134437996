import React, { useEffect, useState } from "react";
import AccessContainer from "../common/AccessContainer";
import Spinner from "react-bootstrap/Spinner";
import MUIDataTable from "mui-datatables";

const parentColumns = [
  { name: "userDetails", label: "User" },
  { name: "category", label: "Category" },
  { name: "action", label: "Action" },
  {
    name: "_ts",
    label: "Time",
    options: {
      customBodyRender: (item, meta) =>
        `${new Date(item * 1000).toLocaleDateString()} ${new Date(
          item * 1000
        ).toLocaleTimeString()}`,
    },
  },
];

function LocumReportAccessAudit(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(`/api/NexusAudit/GetNexusAuditTrail/LocumBank`)
      .then((response) => response.json())
      .then((items) => {
        setData(items);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);
  return (
    <AccessContainer allowedRoles={["NexusAdmin", "LocumReport"]}>
      {isLoading && <Spinner animation="border" />}
      {data && (
        <MUIDataTable
          data={data}
          columns={parentColumns}
          title="Locum Bank Access Audit"
          options={{
            selectableRows: "none",
            responsive: "scroll",
            downloadOptions: {
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },
          }}
        />
      )}
    </AccessContainer>
  );
}
export default LocumReportAccessAudit;
