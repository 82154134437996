import React, { useEffect, useState } from "react";
import AccessContainer from "../common/AccessContainer";
import Spinner from "react-bootstrap/Spinner";
import MUIDataTable from "mui-datatables";
//al.[AuditDate], al.UpdatedBy, al.[Action], ali.[Field], ali.[OldValue], ali.[NewValue]
const parentColumns = [
  {
    name: "Action",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        switch (value) {
          case "C":
            return "Created";
          case "U":
            return "Modified";
          case "D":
            return "Deleted";
          default:
            return "N/A";
        }
      },
    },
  },
  {
    name: "AuditDate",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => (
        <>{new Date(value).toLocaleString("en-GB")}</>
      ),
    },
  },
  { name: "UpdatedBy", label: "Modified By" },
  {
    name: "ItemTypeId",
    label: "Type",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (!value) return;
        return /\(([^)]+)\)/.exec(value)[1];
      },
    },
  },
  {
    name: "Description",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (!value) return;
        return (
          <div
            style={{ whiteSpace: "pre-line" }}
            dangerouslySetInnerHTML={{ __html: value }}
          />
        );
      },
    },
  },
  {
    name: "JournalDateTime",
    label: "Journal Date Time",
  },
  { name: "CaseResponsibleVet", label: "Responsible" },
  { name: "ResponsibleVet", label: "Vet" },
];

function JournalItemTable(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (props.recordId) {
      setIsLoading(true);
      fetch(`/api/HelixAudit/JournalItems/${props.recordId}`)
        .then((response) => response.json())
        .then((items) => {
          setData(items);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [props.recordId]);
  return (
    <AccessContainer allowedRoles={["NexusAdmin", "HelixAudit"]}>
      {isLoading && <Spinner animation="border" />}
      {data && (
        <MUIDataTable
          data={data}
          columns={parentColumns}
          title={`Item #${props.recordId}`}
          options={{ selectableRows: "none", responsive: "vertical" }}
        />
      )}
    </AccessContainer>
  );
}
export default JournalItemTable;
