import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import AccessContainer from "../common/AccessContainer";

const columns = [
  { field: 'id', headerName: 'DNIS', width: 260 },
  { field: 'FullTelephoneNumber', headerName: 'Full Telephone Number', width: 260 },
  { field: 'Site', headerName: 'Site', width: 260 },
  {
    field: 'CallType',
    headerName: 'Call Type',
    width: 260,
  },
  {
    field: 'RoutingProfile',
    headerName: 'Routing Profile',
    width: 260,
  },
];

export default function ViewNumbers() {
  const [numbers, setNumbers] = useState([]);

  useEffect(() => {
    fetch(`/api/CallFlow/GetAllNumbersForClinicsMitel/`)
        .then((response) => response.json())
        .then((items) => {
        setNumbers(items);
        })
        .catch((err) => {
        console.log(err);
        });
  }, []);

  let numbersArray = []

  for (let i=0; i < numbers.length; i++) {
    let numberRecord = {}
    numbers[i].forEach(function (column) {
        let columnName = column.metadata.colName;
        numberRecord[columnName] = column.value;
     })
     numbersArray.push(numberRecord);
  }

  return (
    <AccessContainer
        allowedRoles={[
            "NexusAdmin",
            "MitelAdmin"
        ]}
        >
      <div style={{ border:'0px',padding:'0 25px', height: 'calc(100vh - 120px)', width: '100%' }}>
        <DataGrid
            rows={numbersArray}
            columns={columns}
            initialState={{
            pagination: {
                paginationModel: { page: 0, pageSize: 5 },
            },
            }}
        />
      </div>
    </AccessContainer>
  );
}