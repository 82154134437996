import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import logo from "../../assets/default-monochrome-white.svg";
import Animal from "react-animals";
function PublicHome() {
  return (
    <header className="App-header">
      <Container className="mb-3 mt-3">
        <Row className="justify-content-md-center mb-3">
          <Col md={4}>
            <Button
              href={`/.auth/login/aad?post_login_redirect_uri=${window.location.href}`}
            >
              Please Login
            </Button>
          </Col>
        </Row>
        <Row>
          <img src={logo} className="App-logo" alt="logo" />
          <Row
            style={{
              marginTop: 45,
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Animal dance />
            <Animal dance />
            <Animal dance />
          </Row>
        </Row>
      </Container>
    </header>
  );
}
export default PublicHome;
